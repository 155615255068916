import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import ContactAnimation from '../components/ContactAnimation'
import HaveQuestions from '../components/StillHaveQuestions'
import ShopWatchCategory from '../components/ShopWatchCategory'

const ContactInfoSubmittedThankYou = () => {
  return (
    <Layout className={''} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />

      <section className="w-10/12 mx-auto max-w-[500px] my-10">
        <h1 className="text-4xl text-gray-600 font-bold text-center">MESSAGE SENT!</h1>
        <ContactAnimation />
        <p className="text-lg">
          Your message has been received. Please allow up to 48 hours for a response. For a quicker
          response, you can call our showroom during business hours Monday through Saturday.
        </p>
      </section>
      <div className="w-10/12 mx-auto">
        <hr />
        <HaveQuestions />
        <hr />
        <ShopWatchCategory />
      </div>
      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  )
}

export default ContactInfoSubmittedThankYou
